import React from "react";
import Graph from "react-graph-vis";

// import "./styles.css";
// need to import the vis network css in order to show tooltip
// import "./network.css";

export default function Flowgraph() {
  const graph = {
    nodes: [
      { id: 1,
        label: `Benefits of 
      Launch My Business 
      Bizpreneurship Program
      `,
       title: "node 1 tootip text" },
      { id: 2, label: `Learn A-Z of business setup.`, title: "node 2 tootip text" },
      { id: 3, label: `Live Practical Business Setup`, title: "node 3 tootip text" },
      { id: 4, label: `Start Earning Business Profits for Lifetime.`, title: "node 4 tootip text" },
      { id: 5, label: `You will have your own Business at the end of the Program.`, title: "node 5 tootip text" }
    ],
    edges: [
      { from: 1, to: 2 },
      { from: 1, to: 3 },
      { from: 1, to: 4 },
      { from: 1, to: 5 }
    ]
  };
  const myHeight = Math.round(parseInt(window.innerHeight) * 0.9) + 'px';
  const myWidth = Math.round(parseInt(window.innerWidth) * 0.9) + 'px';
  const options = {
    align: 'left',
    layout: {
      hierarchical: `false`
    },
    interaction:{
        dragNodes: false,
        dragView:false,
        zoomView:false,
        selectable:false,
        selectConnectedEdges: false,
      },
      physics:{
        stabilization: true,
        repulsion: {
            nodeDistance: 200 // Put more distance between the nodes.
          },
          barnesHut:{
            springConstant: 0,
            avoidOverlap: 1,
            centralGravity:0.2
          }
      },
    edges: {
      color: "#000000",
      widthConstraint: {
        maximum: 90,
        minimum:50,
      },
    },
    height: myHeight,
    width: myWidth,
    clickToUse: false,
    autoResize: true,
    nodes: {
        shape: "ellipse",
        color:{
            background:'#D2E5FF'},
        margin: 10,
        widthConstraint: {
            maximum: 200,
          },
          fixed:false
      },
  };

  const events = {
    // select: function(event) {
    //   var { nodes, edges } = event;
    // }
  };
  const parserOptions = {
    edges: {
      inheritColor: false
    },
    nodes: {
      fixed: true,
      parseColor: false,
      shape: "box",
    }
  }
  return (
    <Graph
      graph={graph}
      options={options}
      events={events}
      parserOptions= {parserOptions}
      getNetwork={network => {
        //  if you want access to vis.js network api you can set the state in a parent component using this property
      }}
    />
  );
}
