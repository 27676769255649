import React, { Component } from 'react';
// import './main'
import { Link } from 'react-router-dom';
export class Whatsapp extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <div id="mybutton">
            <button class="feedback">
              <Link to={{ pathname:'https://api.whatsapp.com/send?phone=+919329055548&text=Hi%20we%20want%20to%20register%20for%20launch%20my%20business'}} target="_blank" rel="noreferrer">
              <i className="mdi mdi-whatsapp text-white"></i>
              </Link>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Whatsapp;
