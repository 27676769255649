
import Layout4 from "../src/Layout4";
// import Login from "../src/component/Login";
// import SignUp from "../src/component/SignUp";
// import ForgotPassword from "../src/component/ForgotPassword";

const routes = [
  { path: "/", component: Layout4 },
  // { path: "/Login", component: Login },
  // { path: "/SignUp", component: SignUp },
  // { path: "/ForgotPassword", component: ForgotPassword },
];

export default routes;
