import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import { Link } from "react-router-dom";

// Import Logo
// import logodark from "../../assets/images/black.png";
// import logolight from "../../assets/images/white.png";
// import lono from "../../assets/images/lono.png";
import ScrollspyNav from "./Scrollspy";

class NavbarPage extends Component {
  // render() {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        <Navbar
          expand="lg"
          fixed={this.props.top === true ? "top" : ""}
          className={this.props.navClass + " navbar-custom sticky sticky-dark"}
          id="navbar"
        >
          <Container>
          {/* LOGO */}
          <NavbarBrand className="navbar-brand logo " href="/">
            {/* <img src={lono} alt="logo"/> */}
              {this.props.imglight === true ? (
                <h1>&#128640;<span class="lclass">L</span><span class="aclass">aunch</span> <span class="mclass">M</span><span class="yclass">y</span> <span class="bclass">B</span><span class="uclass">usiness</span></h1>
              ) : (
                <h1> &#128640;<span class="lclass">L</span><span class="aclass">aunch</span> <span class="mclass">M</span><span class="yclass">y</span> <span class="bclass">B</span><span class="uclass">usiness</span></h1>
                )}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className=" navbar-collapse"
            >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav className="navbar-nav ml-auto navbar-center" id="navbar-navlist">
                  {this.props.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink
                        className={item.navheading === "Home" ? "active" : ""}
                        href={"#" + item.idnm}
                      >
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                
              </ScrollspyNav>
              {/* <ul className="navbar-nav navbar-center">
                  <li className="nav-item">
                    <Link to="/Login" className="nav-link">Log In</Link>
                  </li>
                  <li className="nav-item d-inline-block d-lg-none">
                    <Link to="/SignUp" className="nav-link">Sign Up</Link>
                  </li>
                </ul>*/}
                <div className="navbar-button d-none d-lg-inline-block">
                  <Link to={{ pathname:"http://user.launchmybusiness.in/user/login"}} target="_blank" className="btn btn-sm btn-soft-primary btn-round">Login</Link>
                </div> 
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}
// }
export default NavbarPage;